import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, SectionHeading } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import DataTable from "../../DataTable";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  /* min-height: 100svh; */
  width: 100%;
  /* background-color: #02348d; */
  padding: 0 !important;
  position: relative;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  /* min-height: calc(100svh - 70px); */
  width: 100%;
  padding: 0 50px !important;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;

const HeroSection = () => {
  return (
    <>
          <CustomGrid container ai="start" jc="start"  sx={{background:"#282855"}}>
                <DataTable/>
          </CustomGrid>
    </>
  );
};

export default HeroSection;
