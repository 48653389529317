import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import GroupsIcon from "@mui/icons-material/Groups";
import MailIcon from "@mui/icons-material/Mail";
import HomeIcon from "@mui/icons-material/Home";
import logo from "../../../assets/images/logo.png"
import LogoutIcon from "@mui/icons-material/Logout";
import { Grid } from "@mui/material";
import {
  DivWrap,
  HeadingGrid,
  IconButtonOpen,
  IconWraper,
  ListItemsText,
  MobileDrawer,
  UList,
} from "./style";
import { DataImage } from "../../Styles/style";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MobileDrawer, {shouldForwardProp: (prop) => prop !== "open",})
(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer(props) {
  // const router = useRouter();
  const { children, page } = props;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleLogOut = () => {
    // Cookies.remove("user");
    // router.push("/login");
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex",minHeight:"100dvh" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ color: "#fff", backgroundColor: "#262033" }}
      >
        <Toolbar>
          <IconButtonOpen
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButtonOpen>
          <DataImage alt="Logo" src={logo} mw="150px"></DataImage>
          {/* <Typography variant="h6" noWrap component="div">
            ADMIN DASHBOARD
          </Typography> */}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <DivWrap>
          <UList>
            {["Servers","Ads"].map(
              (text, index) => (
                <ListItem
                  key={text}
                  disablePadding
                  sx={{
                    display: "block",
                    backgroundColor: page === text ? "#130036a1" : "",
                  }}
                >
                  <ListItemButton
                    href={text === "Servers" ? "/server-dashboard" : `/ads-dashboard`}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {index === 0 ? (
                        <HomeIcon
                          sx={{
                            display: "block",
                            color: page === text ? "#fff" : "#a47eeb",
                          }}
                        />
                      ) : index === 1 ? (
                        <ListAltIcon
                          sx={{
                            display: "block",
                            color: page === text ? "#fff" : "#130036a1",
                          }}
                        />
                      ) : index === 2 ? (
                        <ReceiptLongIcon
                          sx={{
                            display: "block",
                            color: page === text ? "#fff" : "#130036a1",
                          }}
                        />
                      ) : index === 3 ? (
                        <GroupsIcon
                          sx={{
                            display: "block",
                            color: page === text ? "#fff" : "#130036a1",
                          }}
                        />
                      ) : (
                        <MailIcon
                          sx={{
                            display: "block",
                            color: page === text ? "#fff" : "#130036a1",
                          }}
                        />
                      )}
                    </ListItemIcon>
                    <ListItemsText
                      primary={text}
                      sx={{
                        opacity: open ? 1 : 0,
                        fontFamily: "OpenSansBold",
                        margin: "10px 0",
                        color: page === text ? "#fff" : "#a47eeb",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              )
            )}
          </UList>
          {/* <IconWraper onClick={handleLogOut}>
            <LogoutIcon sx={{ color: "var(--primary-color)" }} />
            <SectionSubHeading
              fs="10px"
              fm="OpenSansBold"
              color="var(--primary-color)"
            >
              LOGOUT
            </SectionSubHeading>
          </IconWraper> */}
        </DivWrap>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1,background:"#282855"}}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
