import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { FetchDeleteData, FetchGetData, FetchUpdateData, GetData } from "../FetchData";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import styled from "@emotion/styled";
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, Grid, Switch } from "@mui/material";
import { CustomGrid, SectionHeading } from "../../Styles/style";
import EditProjectData from "../form";
import CustomButton from "../button";
import { toast } from "react-toastify";
const DeleteGrid = styled(Grid)`
  min-height: calc(100vh - 500px);
  background-color: gray;
  /* opacity: 0.6; */
  display: flex;
  justify-content: center;
  align-items: center;
`;
const columns = [
  { id: "ad_link", label: "Ad Link", minWidth: 100 },
  { id: "ad_type", label: "Ad Type", minWidth: 100 },
  { id: "count", label: "Ad Count", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
];

function createData(
  ad_link,
  ad_type,
  count,
  status,
) {
  return {
    ad_link,
    ad_type,
    count,
    status,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};



export default function AdsDataTable() {


  const [page, setPage] = React.useState(0);
  const [DeleteTriger, setDeleteTriger] = React.useState(true);
  const [pendingrows, setPendingRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageType, setPageType] = React.useState("");
  const [rowData, setrowData] = React.useState("");
  const [count, setcount] = React.useState(0);
  const [Loading, setLoading] = React.useState(false);
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleSwitchChange = (link,status) => {
    setLoading(true)
    let state = status==="In Active" ? true  : false
    handelSubmitUpdate(link,state);
  };
  const handleClickOpen = (link) => {
    console.log("link:",link);
    
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const handelSubmitUpdate = async (adLink,isChecked) => {
    const endPoint = "updateAdStatus";
    const method = "PUT";
    const requestData = { adLink: adLink,status:isChecked };
    const result = await FetchUpdateData(endPoint, requestData, method);
    console.log("result:",result);
    
    if (result?.message === "updated") {
      handleFetchData()
    } else {
      return false;
    }
  };
  const handelSubmitDelete = async (Link) => {
    const endPoint = "deleteDataByLink";
    const method = "DELETE";
    const requestData = { adLink: Link };
    const result = await FetchDeleteData(endPoint, requestData, method);
    if (result?.message === "succes") {
      setOpen(false);
      handleFetchData()
      toast.success("Ad deleted");
    } else {
      setOpen(false);
      toast.error("Something went wrong");
    }
  };
  const handleCancelBtnClick = (orderId) => {
    setPageType("");
  };
  const handleEditbtnClick = (row) => {
    setPageType("editProject");
  };
  const handleDeleteBtnClick = (row) => {
    setrowData(row);
    setPageType("deleteProject");
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  const handleFetchData = async () => {
    const endPoint = "getAds";
    const method = "GET";
    const result = await GetData(endPoint, method);
    if (result?.message === "succes") {
      // console.log(result.data)
      const responseData = result.data;
      console.log("responsesss", result.data[0].status);
      setLoading(false)

      const newData = responseData.map((row) => {
          return createData(
            row?.ad_link,
            row?.ad_type,
            row?.count,
            row?.status == true ? "Active" : "In Active",
          );
      });
      setPendingRows(newData);
    } else {
      return false;
    }
  };
  const fetchServer = ()=>{
    setLoading(true)
    handleFetchData();
    setPendingRows([]);
  }
  React.useEffect(() => {
    if(count === 0){
      setcount(1)
    }else if(count === 1){
      setLoading(true)
      handleFetchData();
      setcount(2)
    }
  }, [count]);
  

  return (
    
    <>
    <Box display={Loading ? "flex" : "none"} bgcolor="#000000c2" flexDirection="column" alignItems="center" justifyContent="center" position="fixed" minHeight="100dvh" width="100%" zIndex={999}>
      <CircularProgress color="secondary"  />
      <Typography mt="10px" variant="h5" color="#fff">
          Please wait...
      </Typography>
    </Box>
    <CustomGrid p="20px 0px 0px 10px" item xs={12} ai="start" jc="space-between">
        <SectionHeading fs="20px" c="#fff" sx={{textTransform:"none !important"}}>Fetch Again if your results not seems accurate</SectionHeading>
        <Box >
        <CustomButton
          sx={{ width: "140px" }}
          m="0px 10px 10px 0"
          disabled={Loading ? true: false}
          onClick={handleEditbtnClick}
        >
          New Add
        </CustomButton>
        <CustomButton
          sx={{ width: "140px" }}
          m="0px 0 10px"
          disabled={Loading ? true: false}
          onClick={fetchServer}
          >
          Fetch Again
        </CustomButton>
          </Box>
        </CustomGrid>
        <CustomGrid item xs={12} p="10px 0px 0px 10px" >
      {pageType === "editProject" ? (
        <EditProjectData
          setPageType={setPageType}
          rowData={rowData}
          DeleteTriger={DeleteTriger}
          setDeleteTriger={setDeleteTriger}
          handleFetchData={handleFetchData}
        />
      ) : pageType === "deleteProject" ? (
        <>
          <DeleteGrid item xs={12}>
            {isAlertVisible && (
              <div className="alert-container">
                +{" "}
                <div style={{ transition: "1s" }} className="alert-inner">
                  Project Deleted
                </div>
                +{" "}
              </div>
            )}
            <div>
              <SectionHeading
                fs="20px"
                ta="center"
                Dis="none"
                m_after="10px 0"
                m="10px 0"
                >
                DELETE PROJECT ?
              </SectionHeading>
              <div style={{ display: "flex", gap: "30px" }}>
                <Button
                  variant="filled"
                  onClick={handleCancelBtnClick}
                  sx={{
                    background: "blue",
                    color: "#fff",
                    border: "1px solid blue",
                    borderRadius: "2px !important",
                  }}
                  p_hover="8px 35px"
                  b="1px solid var(--primary-color)"
                  fc="var(--primary-color)"
                  bgc="#fff"
                  >
                  CANCEL
                </Button>
                <Button
                  variant="filled"
                  onClick={handelSubmitDelete}
                  sx={{
                    background: "red",
                    color: "#fff",
                    border: "1px solid red",
                    borderRadius: "2px !important",
                  }}
                  p_hover="8px 35px"
                  b="1px solid red"
                  fc="red"
                  bgc="#fff"
                >
                  DELETE
                </Button>
              </div>
            </div>
          </DeleteGrid>
        </>
      ) : (
        <>
          <Box sx={{ width: "100%" }}>
              <Paper
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  minHeight: "calc(100vh - 250px)",
                }}
              >
                <TableContainer sx={{ minHeight: "calc(100vh - 250px)",border:"1px solid #666666"}}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth ,fontWeight:"900",fontSize:"16px" ,background:"transparent",color:"#fff" }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                        <TableCell
                          key="actions"
                          align="center"
                            style={{ minWidth: 50 ,fontWeight:"900",fontSize:"16px" ,background:"transparent",color:"#fff" }}
                          >
                          Change Activity
                        </TableCell>
                        <TableCell
                          key="Delete"
                          align="center"
                            style={{ minWidth: 50 ,fontWeight:"900",fontSize:"16px" ,background:"transparent",color:"#fff" }}
                          >
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pendingrows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row?.code}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    style={{fontWeight:"600",fontSize:"14px",color:"#fff" }}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                              <TableCell
                                    style={{fontWeight:"600",fontSize:"14px",color:"#fff" }}
                                    align={"center"}
                                  >
                                    <Switch {...label} checked={row.status==="In Active" ? false  : true } onChange={()=>handleSwitchChange(row.ad_link,row.status)} />
                                  </TableCell>
                                  <TableCell
                                    style={{fontWeight:"600",fontSize:"14px",color:"#fff" }}
                                    align={"center"}
                                  >
                                    <Tooltip title="Delete">
                                      <DeleteIcon
                                        sx={{
                                          fontSize: "30px",
                                          color: "red",
                                          margin: "0 10px",
                                        }}
                                        onClick={()=>handleClickOpen(row.ad_link)}
                                      />
                                    </Tooltip>
                                    <React.Fragment>
                                      <Dialog
                                        open={open}
                                        onClose={handleClose}
                                      >
                                        <DialogTitle 
                                        sx={{minWidth:"300px"}}>
                                          Delete Ad?
                                        </DialogTitle>
                                        <DialogActions>
                                          <Button onClick={handleClose}>Cancel</Button>
                                          <Button onClick={()=> handelSubmitDelete(row.ad_link)} autoFocus>
                                            Delete
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                    </React.Fragment>
                                                                  </TableCell>
                              {/* <Tooltip title="Edit">
                          <EditNoteIcon
                            sx={{
                              fontSize: "30px",
                              color: "var(--primary-color)",
                              margin: "0 10px",
                            }}
                            onClick={() => handleEditbtnClick(row)}
                          />
                        </Tooltip> */}
                              {/* <Tooltip title="Delete">
                          <DeleteIcon
                            sx={{
                              fontSize: "30px",
                              color: "red",
                              margin: "0 10px",
                            }}
                            onClick={() => handleDeleteBtnClick(row)}
                          />
                        </Tooltip> */}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={pendingrows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{color:"#fff"}}
                  />
              </Paper>
          </Box>
        </>
      )}
      </CustomGrid>
    </>
  );
}
