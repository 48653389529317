import React, { useRef, useState } from "react";
import { AES } from "crypto-js";
import { CircularProgress, Container, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import styled from "@emotion/styled";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import Cookies from "js-cookie";
import CustomButton from "../../Components/button";
import { DataImage } from "../../Styles/style";

import logo from "../../../assets/images/logo.png"
import { API_URL, WEBSITE_URL } from "../../Constant";
import Paragraph from "../../Components/Paragarph";
const InputField = styled(OutlinedInput)`
 background-color: #0A0C0D;
 width:100%;
 max-width: ${(props) => (props.w ? props.w : "calc(100%)")};
&>.MuiInputBase-input{

  border: ${(props) => (props.b ? props.b : "none")};
  border-radius: 5px;
  padding: 5px 10px;
  height: 33px;
  background-color: #0A0C0D;
  color: #fff;
  font-size: 13px;
  :focus {
    outline: 1px solid var(--primary-color);
  }
}
    `;
const Div = styled.div`
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  width: 100%;
  text-align: center;
  padding: 0 30px 30px;
  border-radius: 20px;
  margin: 0;
  top: 50%;
  color: #000;
`;
const ContainerWraper = styled(Container)`
  position: relative;
  overflow: hidden;
  animation: background-gradient 10s;
  animation-iteration-count: infinite;
  backface-visibility: hidden;
  animation-timing-function: ease-in-out;
  background: #13131B;
`;
const Divinput = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
`;
const LoginPageView = () => {

  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  // const router = useRouter()
  const [email, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passError, setPassError] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setisLoading] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleNameChange = (event) => {
    setEmailError(false)
    if (event.key === "Enter") {
      // console.log("here");
      event.preventDefault();
      input2Ref.current.focus();
    } else {
      // setValidatePass("hide");
      setUserEmail(event.target.value);
    }
   
    // console.log(email)
  };
  const handleSubmitLogin = async () => {
    if(!email){
      setEmailError(true)
      return
    }
    if(!password){
      setPassError(true)
      return
    }
    // console.log(requestData)
    setisLoading(true)
    const endPoint = "login";
    const method = "POST";
    const response = await fetch(`${API_URL}${endPoint}`, {
      method,
      headers: {
        "content-type": "application/json",
      },

      body: JSON.stringify({
        email:email,
        password:password,
    }),
    });
    const result = await response.json();
    setisLoading(false)
    // console.log("result",result);
    if (result?.status === "succes") {
      // console.log("user loged in",result.user_id);
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 60 * 60 * 1000);
      const encryptionKey = "f99e0578f118d0e9d1a81b7f75533b"; // Replace this with your secret key
      const encryptedUser = AES.encrypt(
        result.user_id,
        encryptionKey
      ).toString();
      //console.log("encryptedUser",encryptedUser);
      Cookies.set("user", encryptedUser, { expires: expirationDate });
      window.location.replace(`${WEBSITE_URL}server-dashboard`);
    } else if (result?.status === "All fields are required") {
      setEmailError(true); 
      setPassError(true);
    } else if (result?.status === "Email is required") {
      setEmailError(true);
    } else if (result?.status === "Password is required") {
      setPassError(true);
    } else if (result?.status === "Email is not valid") {
      setEmailError(true);
    } else if (result?.status === "Password is not valid") {
      setPassError(true);
    } else {
      // console.log(result?.status);
    }
  };
  const handleInputChange = (event) => {
    setPassError(false)
    // console.log("event.key",event);
    if (event.key === "Enter") {
        event.preventDefault(); // Prevents form submission if the input is inside a form element
        input2Ref.current.blur(); // Remove focus from the input
        handleSubmitLogin(); // Triggers the button click event
    } else {
        // setValidatePass("hide");
        setPassword(event.target.value);
    }
};

  return (
    <>
      <ContainerWraper maxWidth="xxl">
        <Container
          maxWidth="xs"
          sx={{ height: "100vh", display: "grid", placeItems: "center" }}
        >
          <Div style={{ alignItems: "center" }}>
            <DataImage mw="230px" src={logo} />
            <Divinput>
              <Paragraph variant="body1">
                Email
              </Paragraph>
              <InputField
              
                ref={input1Ref}
                // value={email}
                type="text"
                onKeyUp={handleNameChange}
                placeholder="Enter email"
                
              />
              {emailError && (
                  <Paragraph variant="subtitle" color="red" className={"showP"}>invalid entry</Paragraph>
                )}
                
                <Paragraph marginTop="15px" variant="body1">
                Password
              </Paragraph>
              <InputField
                ref={input2Ref}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff sx={{color:"#fff"}} /> : <Visibility sx={{color:"#fff"}} />}
                    </IconButton>
                  </InputAdornment>
                }                
                onKeyUp={handleInputChange}
                placeholder="Enter password"
                
              />
               {passError && (
                  <Paragraph variant="subtitle" color="red" className={"showP"}>invalid entry</Paragraph>
                )}
              <CustomButton
              disabled={isLoading}
              sx={{width:"100% !important",marginTop:"20px !important"}}
                onClick={handleSubmitLogin}
              >
                {isLoading ? <CircularProgress size={20} sx={{color:"#fff",marginRight:"10px"}}/>:
                  "LOGIN"
                }
              </CustomButton>
            </Divinput>
          </Div>
        </Container>
      </ContainerWraper>
    </>
  );
};

export default LoginPageView;
