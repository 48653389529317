import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import HeroSection from "../../Components/Sections/S1-Hero";
import MiniDrawer from "../../Components/sidebar";
import { ToastContainer } from "react-toastify";
import handleSubmitLogin from "../../Components/validityCheck";
import { WEBSITE_URL } from "../../Constant";

const MainPage = () => {
  const [isLoged, setIsLoged] = useState(false);
  useEffect(() => {
    const validityCheck = async () => {
      const validity = await handleSubmitLogin();
      console.log("validity:", validity);
      validity
        ? setIsLoged(validity)
        :window.location.replace(`${WEBSITE_URL}`);
    };
    validityCheck();
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    // <LoginPageView/>
    <>
      {isLoged && (
        <MiniDrawer page="Servers">
          <HeroSection />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </MiniDrawer>
      )}
    </>
  );
};

export default MainPage;
