import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { FetchDeleteData, FetchGetData, GetData } from "../FetchData";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import styled from "@emotion/styled";
import { Button, CircularProgress, Grid } from "@mui/material";
import { CustomGrid, SectionHeading } from "../../Styles/style";
import EditProjectData from "../form";
import CustomButton from "../button";
const DeleteGrid = styled(Grid)`
  min-height: calc(100vh - 500px);
  background-color: gray;
  /* opacity: 0.6; */
  display: flex;
  justify-content: center;
  align-items: center;
`;
const columns = [
  { id: "flag_link_1x1", label: "Flag", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
  { id: "country_name", label: "Country Name", minWidth: 100 },
  { id: "region", label: "Region", minWidth: 100 },
  { id: "server_name", label: "Server Name", minWidth: 100 },
  { id: "ip", label: "IP", minWidth: 100 },
  { id: "o_vpn_link", label: "Vpn Link", minWidth: 100 },
];

function createData(
  flag_link_1x1,
  status,
  country_name,
  region,
  server_name,
  ip,
  o_vpn_link,
) {
  return {
    flag_link_1x1,
    status,
    country_name,
    region,
    server_name,
    ip,
    o_vpn_link,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DataTable() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [page, setPage] = React.useState(0);
  const [DeleteTriger, setDeleteTriger] = React.useState(true);
  const [pendingrows, setPendingRows] = React.useState([]);
  const [generatingrows, setGeneratingRows] = React.useState([]);
  const [generatedrows, setGeneratedRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageType, setPageType] = React.useState("");
  const [rowData, setrowData] = React.useState("");
  const [count, setcount] = React.useState(0);
  const [Loading, setLoading] = React.useState(false);
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);

  const handelSubmitDelete = async () => {
    const endPoint = "deleteDataById";
    const method = "DELETE";
    const requestData = { orderId: rowData.order_id };
    const result = await FetchDeleteData(endPoint, requestData, method);
    if (result?.message === "succes") {
      setDeleteTriger(!DeleteTriger);
      setPageType("");
    } else {
      return false;
    }
  };
  const handleCancelBtnClick = (orderId) => {
    setPageType("");
  };
  const handleEditbtnClick = (row) => {
    setrowData(row);
    setPageType("editProject");
  };
  const handleDeleteBtnClick = (row) => {
    setrowData(row);
    setPageType("deleteProject");
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  const handleFetchData = async () => {
    const endPoint = "manage-wireguard";
    const method = "POST";
    const result = await GetData(endPoint, method);
    if (result?.message === "succes") {
      // console.log(result.data)
      const responseData = result.data;
      console.log("responsesss", result.data);
      setLoading(false)
      
      const newData = responseData.map((row) => {
          return createData(
            row?.flag_link_1x1,
            row?.status,
            row?.country_name,
            row?.region,
            row?.server_name,
            row?.ip,
            row?.o_vpn_link,
          );
      });
      setPendingRows(newData);
    } else {
      return false;
    }
  };
  const fetchServer = ()=>{
    setLoading(true)
    handleFetchData();
    setPendingRows([]);
  }
  React.useEffect(() => {
    if(count === 0){
      setcount(1)
    }else if(count === 1){
      setLoading(true)
      handleFetchData();
      setcount(2)
    }
  }, [count]);

  return (
    
    <>
    <Box display={Loading ? "flex" : "none"} bgcolor="#000000c2" flexDirection="column" alignItems="center" justifyContent="center" position="fixed" minHeight="100dvh" width="100%" zIndex={999}>
      <CircularProgress color="secondary"  />
      <Typography mt="10px" variant="h5" color="#fff">
          Please wait while fetching servers it would take upto 1 minute
      </Typography>
    </Box>
    <CustomGrid p="20px 0px 0px 10px" item xs={12} ai="start" jc="space-between">
        <SectionHeading fs="20px" c="#fff" sx={{textTransform:"none !important"}}>Fetch Again if your results not seems accurate</SectionHeading>
              
        <CustomButton
          sx={{ width: "140px" }}
          m="0px 0 10px"
          disabled={Loading ? true: false}
          onClick={fetchServer}
        >
          Fetch Again
        </CustomButton>
        </CustomGrid>
        <CustomGrid item xs={12} p="10px 0px 0px 10px" >
      {pageType === "editProject" ? (
        <EditProjectData
          setPageType={setPageType}
          rowData={rowData}
          DeleteTriger={DeleteTriger}
          setDeleteTriger={setDeleteTriger}
        />
      ) : pageType === "deleteProject" ? (
        <>
          <DeleteGrid item xs={12}>
            {isAlertVisible && (
              <div className="alert-container">
                +{" "}
                <div style={{ transition: "1s" }} className="alert-inner">
                  Project Deleted
                </div>
                +{" "}
              </div>
            )}
            <div>
              <SectionHeading
                fs="20px"
                ta="center"
                Dis="none"
                m_after="10px 0"
                m="10px 0"
                >
                DELETE PROJECT ?
              </SectionHeading>
              <div style={{ display: "flex", gap: "30px" }}>
                <Button
                  variant="filled"
                  onClick={handleCancelBtnClick}
                  sx={{
                    background: "blue",
                    color: "#fff",
                    border: "1px solid blue",
                    borderRadius: "2px !important",
                  }}
                  p_hover="8px 35px"
                  b="1px solid var(--primary-color)"
                  fc="var(--primary-color)"
                  bgc="#fff"
                  >
                  CANCEL
                </Button>
                <Button
                  variant="filled"
                  onClick={handelSubmitDelete}
                  sx={{
                    background: "red",
                    color: "#fff",
                    border: "1px solid red",
                    borderRadius: "2px !important",
                  }}
                  p_hover="8px 35px"
                  b="1px solid red"
                  fc="red"
                  bgc="#fff"
                >
                  DELETE
                </Button>
              </div>
            </div>
          </DeleteGrid>
        </>
      ) : (
        <>
          <Box sx={{ width: "100%" }}>
              <Paper
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  minHeight: "calc(100vh - 250px)",
                }}
              >
                <TableContainer sx={{ minHeight: "calc(100vh - 250px)",border:"1px solid #666666"}}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                          key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth ,fontWeight:"900",fontSize:"16px" ,background:"transparent",color:"#fff" }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                        {/* <TableCell
                          key="actions"
                          align="left"
                          style={{ minWidth: 100 }}
                          >
                          Actions
                        </TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pendingrows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              style={{background:"#5c46d62e"}}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row?.code}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                const status = row.status;
                                return (
                                  <TableCell
                                    key={column.id}
                                    style={{fontWeight:"600",fontSize:"14px",background:status === "ok"?"#85ff27":"#fa2b2b" }}
                                    align={column.align}
                                  >
                                    {
                                      column.id === "flag_link_1x1" ?
                                      <img src={value} alt="flag" style={{width:"60px",height:"30px"}}/>
                                      :
                                      <>
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                      </>
                                    }
                                  </TableCell>
                                );
                              })}
                              {/* <CustomButton
                                variant={
                                  row?.status === "DONE"
                                    ? "contained"
                                    : "disabled"
                                }
                                onClick={() => handleEditbtnClick(row)}
                                bgc={
                                  row?.status === "DONE"
                                    ? "#02348D"
                                    : "#F9860B  !important"
                                }
                                sx={{
                                  width: "150px",
                                  borderRadius: "2px !important",
                                }}
                              >
                                {row?.status === "DONE"
                                  ? "Generating Card"
                                  : "Pending Card"}
                              </CustomButton> */}
                              {/* <Tooltip title="Edit">
                          <EditNoteIcon
                            sx={{
                              fontSize: "30px",
                              color: "var(--primary-color)",
                              margin: "0 10px",
                            }}
                            onClick={() => handleEditbtnClick(row)}
                          />
                        </Tooltip> */}
                              {/* <Tooltip title="Delete">
                          <DeleteIcon
                            sx={{
                              fontSize: "30px",
                              color: "red",
                              margin: "0 10px",
                            }}
                            onClick={() => handleDeleteBtnClick(row)}
                          />
                        </Tooltip> */}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={pendingrows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{color:"#fff"}}
                  />
              </Paper>
          </Box>
        </>
      )}
      </CustomGrid>
    </>
  );
}
