import React, { useEffect, useState } from "react";
import { CustomGrid } from "../../Styles/style";
import AOS from "aos";
import "aos/dist/aos.css";
import MiniDrawer from "../../Components/sidebar";
import { ToastContainer } from "react-toastify";
import handleSubmitLogin from "../../Components/validityCheck";
import { WEBSITE_URL } from "../../Constant";
import AdsDataTable from "../../Components/AdsDataTable";

const AdsPage = () => {
  const [isLoged, setIsLoged] = useState(false);
  useEffect(() => {
    const validityCheck = async () => {
      const validity = await handleSubmitLogin();
      console.log("validity:", validity);
      validity
        ? setIsLoged(validity)
        :window.location.replace(`${WEBSITE_URL}`);
    };
    validityCheck();
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    // <LoginPageView/>
    <>
      {isLoged && (
        <MiniDrawer page="Ads">
          <CustomGrid container ai="start" jc="start"  sx={{background:"#282855"}}>
            <AdsDataTable/>
          </CustomGrid>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </MiniDrawer>
      )}
    </>
  );
};

export default AdsPage;
