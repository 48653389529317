import React, {  useState } from "react";
// import { context } from "@/app/view/Dashboard/portfolio";
import styled from "@emotion/styled";
import { Grid, MenuItem, Select } from "@mui/material";
import SectionSubHeading from "../typography/sectionSubHeading";
import CustomButton from "../button";
import { FetchPostData } from "../FetchData";
import { InputField } from "../../Styles/style";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 10px;
  @media (max-width: 599px) {
    flex-direction: ${(props) => (props.fd ? props.fd : "row")};
    align-items: flex-start;
  }
`;
const EditProjectData = (props) => {
  const { rowData, setPageType,handleFetchData} = props;
  const [Link, setLink] = useState(rowData.Link);
  const [AdType, setAdType] = useState("image");
  const [AdStatus, setAdStatus] = useState(true);
  const handelLinkInput = (value) => {
    setLink(value);
  };
  const handelAdTypeInput = (event) => {
    setAdType(event.target.value);
  };
  const handelAdStatusInput = (event) => {
    setAdStatus(event.target.value);
  };

  const handelSubmitUpdate = async () => {
    const endPoint = "addNewAd";
    const requestData = {
      adLink: Link,
      adType: AdType,
      status: AdStatus,
    };
    const method = "POST";
    const result = await FetchPostData(endPoint, requestData, method);
    if (result?.message === "succes") {
      toast.success("New Ad Added succesfuly");
      setPageType("");
      handleFetchData()
    }
  };
  return (
    <>
      <Grid container>
        <Grid item xs={10}>
          <InputDiv fd="column">
            <SectionSubHeading color="#ffffff" fm="OpenSansBold" fs="16px">
              AD Link
            </SectionSubHeading>
            <InputField
              w="80%"
              w599="100%"
              placeholder="link"
              value={Link}
              type="text"
              onChange={(e) => handelLinkInput(e.target.value)}
            />
          </InputDiv>
        </Grid>
        <Grid item xs={10}>
          <InputDiv fd="column">
            <SectionSubHeading color="#ffffff" fm="OpenSansBold" fs="16px">
              Ad Type
            </SectionSubHeading>
            <Select
              id="demo-simple-select"
              value={AdType}
              onChange={handelAdTypeInput}
              sx={{width:"80%",height:"40px",background:"#0A0C0D",color:"#fff"}}
            >
              <MenuItem value="image">Image</MenuItem>
              <MenuItem value="video">Video</MenuItem>
            </Select>
            {/* <InputField w="80%" placeholder="Project Category" value={projectCategory}  onChange={(e) => handelCategoryInput(e.target.value)}/> */}
          </InputDiv>
        </Grid>
        <Grid item xs={10}>
          <InputDiv fd="column">
            <SectionSubHeading color="#ffffff" fm="OpenSansBold" fs="16px">
              Ad Status
            </SectionSubHeading>
            <Select
              id="demo-simple-select"
              value={AdStatus}
              onChange={handelAdStatusInput}
              sx={{width:"80%",height:"40px",background:"#0A0C0D",color:"#fff"}}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>In Active</MenuItem>
            </Select>
            {/* <InputField w="80%" placeholder="Project Category" value={projectCategory}  onChange={(e) => handelCategoryInput(e.target.value)}/> */}
          </InputDiv>
        </Grid>
        <Grid item xs={10}>
          <InputDiv>
            <CustomButton
              onClick={() => setPageType("")}
              sx={{ width: "49%", borderRadius: "2px !important" }}
              p_hover="8px 35px"
            >
              CANCEL
            </CustomButton>
            <CustomButton
              onClick={handelSubmitUpdate}
              sx={{ width: "49%", borderRadius: "2px !important" }}
              p_hover="8px 35px"
            >
              Add
            </CustomButton>
          </InputDiv>
        </Grid>
      </Grid>
    </>
  );
};

export default EditProjectData;

